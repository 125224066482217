import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Snackbar,
    Alert,
} from "@mui/material";
import axios from "axios";
import Logo from "../../Assets/logo.png";
import image from "../../Assets/box3.png";

const Coworking = () => {
    const [animate, setAnimate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        seats: 0,
        amount: 0,
        message: "",
    });
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        setAnimate(true);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "phone" && value.length > 11) {
            setSnackbarMessage("Phone Number cannot be more than 11 Digits");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        if (name === "message" && value.split(" ").length > 200) {
            setSnackbarMessage("Message cannot be more than 200 words");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name) {
            setSnackbarMessage("Name is required");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        if (!formData.email || !emailPattern.test(formData.email)) {
            setSnackbarMessage("Please enter a valid email address");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        if (!formData.phone || formData.phone.length < 11) {
            setSnackbarMessage("Phone Number is required");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        if (!formData.seats) {
            setSnackbarMessage("Number of Seats is required");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        if (formData.message.split(" ").length > 200) {
            setSnackbarMessage("Message cannot be more than 200 words");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        setLoading(true);
        console.log(formData)
        try {
            const res = await axios.post(
                "https://api.burntbrains.com/web/coworking",
                formData
            );
            console.log(`Coworking form response===>`, res);
            setSnackbarMessage(
                "Thank you for reaching out! We've received your message and will get back to you shortly."
            );
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
            setFormData({ name: "", email: "", phone: "", seats: 0, amount: 0, message: "", });
        } catch (error) {
            setSnackbarMessage(error.response?.data?.message || "An error occurred");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div
            style={{
                position: "relative",
                zIndex: 1,
                color: "#fff",
                textAlign: "center",
            }}
        >
            <img src={Logo} alt="Burnt Brains" width={250} style={{paddingTop:15}}/>
            <Box
                // mt={8}
                sx={{
                    height: "83vh",
                    maxWidth: "100%",
                    padding: "1rem",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography variant="h3" textAlign="center" fontWeight="bold" pb={2} color="black"
                    sx={{
                        color: "#FFF",
                        transition: "transform 2s ease",
                        transform: animate ? "translateY(0)" : "translateY(-100%)",
                    }}>
                    <span style={{ color: "#FF5722", }}>Co</span>
                    working
                </Typography>
                <Typography variant="h6" textAlign="center" fontWeight="bold" width={600} pb={3} color="black"
                    sx={{
                        color: "#FFF",
                        transition: "transform 2s ease",
                        transform: animate ? "translateY(0)" : "translateY(100%)",
                    }}>
                    Your <span style={{ color: "#FF5722", }}>Productive </span>
                    Workspace <span style={{ color: "#FF5722", }}>Awaits!</span>
                </Typography>
                <Grid container spacing={5} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={3}>
                        <Box
                            sx={{
                                backgroundColor: "rgba(2, 2, 2, 0.7)",
                                color: "#fff",
                                padding: "35px",
                                border: "2px dashed #fff",
                                borderRadius: "10px 10px 50px 10px",
                                height: "26rem",
                                transition: "transform 2s ease",
                                transform: animate ? "translateX(0)" : "translateX(-75%)",
                                backdropFilter: "blur(10px)",
                                WebkitBackdropFilter: "blur(10px)",
                            }}
                        >

                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                Why Choose Us?
                            </Typography>
                            <Typography variant="body2" textAlign="left" fontSize="14px">
                                Join our dynamic coworking community and work in a professional, inspiring environment. Enjoy high-speed internet, comfortable workstations, and networking opportunities. Sign up now and boost your productivity!
                            </Typography>

                            <Typography variant="h6" fontWeight="bold" gutterBottom mt={2}>
                                Phone:
                            </Typography>
                            <Typography variant="body2">+92 303 4007265</Typography>

                            <Typography variant="h6" fontWeight="bold" gutterBottom mt={2}>
                                Email:
                            </Typography>
                            <Typography variant="body2">faizan@burntbrains.com</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{
                                color: "#fff",
                                backgroundColor: "rgba(2, 2, 2, 0.7)",
                                padding: "20px 30px",
                                border: "2px dashed #fff",
                                borderRadius: "10px 10px 50px 10px",
                                transition: "transform 2s ease",
                                transform: animate ? "translateX(0)" : "translateX(45%)",
                                backdropFilter: "blur(10px)",
                                WebkitBackdropFilter: "blur(10px)",
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        textAlign="left"
                                        fontWeight="bold"
                                        sx={{
                                            color: "#FFF",
                                            transition: "transform 2s ease",
                                            transform: animate ? "translateY(0)" : "translateY(-100%)",
                                        }}
                                    >
                                        Claim <span style={{ color: "#FF5722" }}>Your Desk</span> – <span style={{ color: "#FF5722" }}>Register</span> Now!
                                    </Typography>
                                </Grid>

                                {[
                                    { label: "Name", name: "name", type: "text" },
                                    { label: "Email", name: "email", type: "text" },
                                    { label: "Phone", name: "phone", type: "number" },
                                    { label: "Number of Seats", name: "seats", type: "number" },
                                    { label: "Amount Currently Paying", name: "amount", type: "number" },
                                ].map((field, index) => (
                                    <Grid item xs={12} sm={field.name === "phone" ? 12 : 6} key={index}>
                                        <TextField
                                            label={field.label}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            name={field.name}
                                            type={field.type}
                                            value={formData[field.name]}
                                            onChange={handleChange}
                                            InputLabelProps={{ style: { color: "#FFF" } }}
                                            InputProps={{
                                                sx: {
                                                    borderRadius: "10px",
                                                    color: "#FFF",
                                                    backgroundColor: "transparent",
                                                    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#FFF" },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#FFF" },
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#FFF" },
                                                },
                                            }}
                                        />
                                    </Grid>
                                ))}

                                <Grid item xs={12}>
                                    <TextField
                                        label="Write Your Message"
                                        variant="outlined"
                                        multiline
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        rows={4}
                                        fullWidth
                                        InputLabelProps={{ style: { color: "#FFF" } }}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "10px",
                                                color: "#FFF",
                                                backgroundColor: "transparent",
                                                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#FFF" },
                                                "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#FFF" },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#FFF" },
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} display="flex" justifyContent="flex-start">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        disabled={loading}
                                        sx={{
                                            backgroundColor: "#ff6b5b",
                                            color: "#fff",
                                            padding: "10px 20px",
                                            borderRadius: "10px",
                                            textTransform: "none",
                                            "&:hover": {
                                                backgroundColor: "#ff4b3b",
                                            },
                                            "&.Mui-disabled": {
                                                backgroundColor: "#888888",
                                                color: "#cccccc",
                                            },
                                        }}
                                    >
                                        {loading ? "Submitting..." : "Submit"}
                                    </Button>
                                </Grid>
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Coworking;
